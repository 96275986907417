import React from 'react';
import style from './ProgressBar.module.scss';

export default function ProgressBar(props){

    return(
        <>
        <p>{props.title}</p>
        <div className={style.border}>
            <div className={style.progressBar + ' pgb'} data-percentage={props.percentage}></div>
        </div>
        </>
        );
}