import React from 'react';
import style from './Home.module.scss';
import ProgressBar from './utils/ProgressBar';
import Card from './utils/Card';
import Typed from 'typed.js';


  export default class Home extends React.Component{

    constructor(props){
        super(props);
        this.cardRef = React.createRef();
        this.state={
            
        };    
    }
    componentDidMount() {
        var options = {
            strings: ['I am Web Developer', 'Frontend', 'Backend', 'Freelance !'],
            typeSpeed: 100,
            backSpeed: 70,
            startDelay:2000,
            smartBackspace:false,
            backDelay: 2000,
            showCursor:false,
            loop:true,
            fadeOut:false,
            cursorChar: "|",
          };
          
        let typed = new Typed('#typed', options);            
    }  




    addclassName = (e) =>{
        e.target.classList.add('letterAfter');
        setTimeout(function(){
            e.target.classList.remove('letterAfter');
        }, 1000)
      }    
    
    render(){
        return(
            <>
            <div className={style.bg_img} ></div>
            <div className='container show'>
                <div className={style.home_text}>
                    <div className={style.big_text}>
                        <h1 className="home_title">
                            <div className="letter" onMouseOver={this.addclassName} >H</div>
                            <div className="letter" onMouseOver={this.addclassName} >e</div>
                            <div className="letter" onMouseOver={this.addclassName} >l</div>
                            <div className="letter" onMouseOver={this.addclassName} >l</div>
                            <div className="letter" onMouseOver={this.addclassName} >o</div>
                        </h1>
                    </div>
                    <span id='typed' className={style.typed}></span>                  
                </div>
            </div>
            <div className='container' ref={this.cardRef}>
                <div className='card'>
                    <div className='card_body'>
                        <div className={style.cardBodyLeft}>
                            <div className={style.cardBody_top}>
                                <div className={style.photo}>
                                    <img src="./img/me.jpg" alt='Julien Ochsenbein'></img>
                                    <div className={style.img_overlay}><p>J.O</p></div>
                                </div>
                                <div className={style.me}>
                                    <p><b>Nom: </b>Julien Ochsenbein</p>
                                    <p><b>Profile: </b>Fullstack Developer</p>
                                    <p><b>Email: </b>julien.ochsenbein@gmail.com</p>
                                    <p><b>Tel: </b>+41 (0) 76 345 78 40</p>
                                </div>
                            </div>
                            <div className={style.cardBody_bottom}>
                                <h2>Skill</h2>
                                {<ProgressBar title='HTML 99%' percentage='99' />}
                                {<ProgressBar title='CSS3 70%' percentage='70' />}
                                {<ProgressBar title='PHP 80%' percentage='80' />}
                                {<ProgressBar title='Javascript 75%' percentage='75' />}                                                 
                            </div>

                        </div>
                        <div className={style.cardBodyRight}>
                            <h1>A propos de moi<div className='underline'></div></h1>
                            <p>
                            Passionné d'informatique depuis mon plus jeune âge, j'ai toujours apprécié le développement web à travers la création de sites ou d'applications. J'ai donc de très bonnes connaissances en HTML/CSS – PHP (Symfony) – MYSQL, de bonnes connaissances en Javascript et certaines notions en React et API Plateform.<br />Ma profession n'étant pas du tout dans ce domaine, j'ai obtenu en CFC d'ébénisterie et travaillé ces dernières années en tant que menuisier.<br />De nature calme et concentrée, je suis autodidacte et j'apprécie les défis quotidiens.
                            </p>
                            <h2>Langues</h2>
                            {<ProgressBar title='Français' percentage='100' />}                                                 
                            {<ProgressBar title='Anglais' percentage='70' />}                                                 
                        </div>
                    </div>
                </div>
            </div>          
            <div className='container'>
                <div className={style.stack}>
                    <Card title='Développement web !' img='./img/programmer.png' description={<>De A à Z ! <br /> D'après l'idée que vous avez de votre site ou application web, je peux créer une maquette, développer votre projet, acquérir votre nom de domaine, votre hébergement et bien entendu mettre le projet final en ligne.</>} />
                    <Card title='Responsive design' img='./img/web-design.png' description='Tous les sites et applications web développer sont dits "Responsive Design" pour être entièrement compatible sur un ordinateur de bureau, une tablette ou un smartphone.' />
                    <Card title='moderne et dynamique !' img='./img/coding.png' description="Que ce soit une application web complexe ou une présentation sur une page, mes connaissances dans les langages tels que PHP ou Javascript allié à des frameworks comme Symfony ou React fera de votre projet un site ou application web développé avec des outils d'actualité." />
                    <Card title='A votre écoute' img='./img/conversation.png' description="Prenons le temps ensemble d'établir vos besoins et vos envies comme les fonctionnalités ou le design" />
                </div>
            </div> 
            <div className='title'>
                    <h1>Portfolio<div className='underline'></div></h1>
            </div>
            <div className='container container-min'>
                <div className='card portfolio'>
                    <a href='https://stockboxlocation.ch/' target='_new' className={style.p_card}>
                        <div className={style.txt_overlay}>StockBox</div>
                        <img src='./img/stockbox.jpg' alt='StockBox'></img>
                        <p className={style.site_work}>Base Wordpress</p>
                        <p className={style.card_desc}>Présentation de l'entreprise, liste de prix et formulaire de contact</p>   
                        <p className={style.site_date}>&nbsp;</p>           
                        <div className={style.bottom}></div>
                    </a>  
                    <a href='https://otime.ch' target='_new' className={style.p_card}>
                        <div className={style.txt_overlay}>Otime</div>
                        <img src='./img/otime.jpg' alt='Otime - Gestion des heures de travail pour PME'></img>
                        <p className={style.site_work}>Web application</p>   
                        <p className={style.card_desc}>Application web pour la gestion des heures de travail pour PME</p>   
                        <p className={style.site_date}>&nbsp;</p>           
                        <div className={style.bottom}></div>
                    </a> 
                    <a href='https://jmforestier.ch/' target='_new' className={style.p_card}>
                        <div className={style.txt_overlay}>Men. Forestier</div>
                        <img src='./img/jmforestier.jpg' alt='Men. Forestier'></img>
                        <p className={style.site_work}>Base Wordpress</p>   
                        <p className={style.card_desc}>Site web de présentation de l'entreprise avec galerie photo et formulaire de contact.</p>   
                        <p className={style.site_date}>&nbsp;</p>           
                        <div className={style.bottom}></div>
                    </a>                                
                    <a href='https://shop.36bougies.ch/' target='_new' className={style.p_card}>
                        <div className={style.txt_overlay}>36 Bougies</div>
                        <img src='./img/36bougies.jpg' alt='36 Bougies - Fabrication artisanal et vente en ligne de bougies'></img>
                        <p className={style.site_work}>Base Prestashop</p>   
                        <p className={style.card_desc}>Shop e-commerce de vente de bougies</p>   
                        <p className={style.site_date}>&nbsp;</p>           
                        <div className={style.bottom}></div>
                    </a>                                       
                    <a href='https://studio60.ch/' target='_new' className={style.p_card}>
                        <div className={style.txt_overlay}>BNI APP</div>
                        <img src='./img/bni.jpg' alt='BNI - Studio60'></img>
                        <p className={style.site_work}>Web application</p>   
                        <p className={style.card_desc}>Mantadé par Studio60 pour la création d'une application de facturation pour l'association BNI.</p>   
                        <p className={style.site_date}>&nbsp;</p>           
                        <div className={style.bottom}></div>
                    </a>              
                    <a href='https://fiduvgoumoens.ch/' target='_new' className={style.p_card}>
                        <div className={style.txt_overlay}>Fid. Von Goumoens</div>
                        <img src='./img/fiduvgoumoens.jpg' alt='Fiduciaire Von Goumoens'></img>
                        <p className={style.site_work}>Base Wordpress</p>   
                        <p className={style.card_desc}>Présentation de la fiduciaire Von Goumoens - Activités et formulaire de contact</p>   
                        <p className={style.site_date}>&nbsp;</p>           
                        <div className={style.bottom}></div>
                    </a>     
                    <a href='#' target='_new' className={style.p_card}>
                        <div className={style.txt_overlay}>Design coiffure Goumoens-la-ville</div>
                        <img src='./img/coiffure.jpg' alt='Design Coiffure'></img>
                        <p className={style.site_work}>Web App</p>   
                        <p className={style.card_desc}>Création d'une web application de rappel de rendez-vous par sms pour un salon de coiffure</p>   
                        <p className={style.site_date}>&nbsp;</p>           
                        <div className={style.bottom}></div>
                    </a>   
                    <a href='https://beartson.com/' target='_new' className={style.p_card}>
                        <div className={style.txt_overlay}>Beartson APP</div>
                        <img src='./img/beartson.jpg' alt='Beartson QR App'></img>
                        <p className={style.site_work}>Web application</p>   
                        <p className={style.card_desc}>Mantadé par Beartson Technology pour la création d'une application de gestion des mises à jour et suivi par QR code</p>   
                        <p className={style.site_date}>&nbsp;</p>           
                        <div className={style.bottom}></div>
                    </a>                                             
                </div>                 
            </div>                    
            </>
        )
    }
}
