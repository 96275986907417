import React from 'react';
import style from './Contact.module.scss';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';


class Contact extends React.Component{

    constructor(props){
        super(props);
        this.state={
            formIsSend : false
        };        
    }
       

    componentDidMount() { 
    }

    formSubmit = (values, actions) => {
        
        fetch('https://julien-ochsenbein.ch/mail.php',{
            method:'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify(values, null, 2)
        }).then((response) => {
                if(response.status === 200){
                    this.setState({formIsSend : true})
                    actions.resetForm({values:''});
                    actions.setSubmitting(true);
                }
        })   
    };

    messageSend = () => {
        document.getElementById('contactform').style.visibility = 'hidden';

        setTimeout(
            () => {
            this.setState({formIsSend:false})
            document.getElementById('contactform').style.visibility = 'visible';
            }
            , 5000);

            return (<div className={style.confirmationForm}>Email bien envoyé, merci !</div>)          
    }



    SwissPhoneRegExp = /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/;
    formSchema = Yup.object().shape({
        lastname: Yup.string().min(3, 'Le nom est trop court !').max(100, 'Le nom est trop long !').required('Renseignez votre nom svp.'),
        company: Yup.string().min(3, 'Le nom de l\'entreprise est trop court !').max(100, 'Le nom de l\'entreprise est trop long !'),
        email: Yup.string().email('L\'email indiqué n\'est pas valide').max(255, 'Votre email est trop long').required('Renseignez votre email svp.'),
        phone: Yup.string().matches(this.SwissPhoneRegExp, 'Veuillez indiquer un numéro de téléphone valide !'),
        message: Yup.string().min(5, 'Votre message est trop court !').max(1000, 'Votre message est trop long').required('Renseignez un message svp.'),
    });

    render(){
        return(
            <>
            {this.state.formIsSend ? this.messageSend() : null }
            <div className={style.formbg}>   
                <div className='title'>
                    <h1>Contactez-moi !<div className='underline'></div></h1>
                </div>            
                <div className='container container-min form' id='contactform'>
                    <div className={style.formulaire + ' pr-10'}>
                        <Formik
                        validationSchema= {this.formSchema}
                        onSubmit={ this.formSubmit }
                        initialValues={{ lastname:'', company:'', email:'', phone:'', message:'' }}>
                            {
                                (
                                    {
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        values,
                                        isSubmitting,
                                        errors,
                                        touched
                                    }
                                ) => (
                                    <Form>
                                        <Field name="lastname">
                                            {({ field, form: { touched, errors } }) => (
                                            <div>
                                                {touched.lastname &&
                                                errors.lastname ?
                                                <div className={style.inputError}>{errors.lastname}</div>:null}                                                    
                                                <input type="text" {...field} placeholder="*Nom"/>
                                            </div>
                                            )}
                                        </Field>
                                        <Field name="company">
                                            {({ field, form: { touched, errors } }) => (
                                            <div>
                                                {touched.company &&
                                                errors.company ?
                                                <div className={style.inputError}>{errors.company}</div>:null}                                                    
                                                <input type="text" {...field} placeholder="Entreprise"/>
                                            </div>
                                            )}
                                        </Field>
                                        <Field name="email">
                                            {({ field, form: { touched, errors } }) => (
                                            <div>
                                                {touched.email &&
                                                errors.email ?
                                                <div className={style.inputError}>{errors.email}</div>:null}
                                                <input type="email" {...field} placeholder="*Email"/>                                                    
                                            </div>
                                            )}
                                        </Field>
                                        <Field name="phone">
                                            {({ field, form: { touched, errors } }) => (
                                            <div>
                                                {touched.phone &&
                                                errors.phone ?
                                                <div className={style.inputError}>{errors.phone}</div>:null}
                                                <input type="text" {...field} placeholder="Téléphone"/>                                                    
                                            </div>
                                            )}
                                        </Field>
                                        <Field name="message">
                                            {({ field, form: { touched, errors } }) => (
                                            <div>
                                                {touched.message &&
                                                errors.message ?
                                                <div className={style.textareaError}>{errors.message}</div>:null}
                                                <textarea {...field} placeholder="*Votre message"/>                                                    
                                            </div>
                                            )}
                                        </Field>
                                        <button type="submit" className="btn" disabled={isSubmitting}>Envoyer</button>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                    <div className={style.contactImg}>
                            <div className={"row text-center "}>
                                <img src='./img/email.png' alt='Email me'></img>
                            </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default Contact