import React from 'react';

export default class Observer extends React.Component{

    componentDidMount() {
        //Definit les element à afficher
        const containers = document.querySelectorAll('.container')
        const pgb = document.querySelectorAll('.card_body');

        
        const ContainersObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                entry.target.classList.toggle("show", entry.isIntersecting)
            })
        },
        {
            threshold: 0
        });
        
        containers.forEach(container =>{
            ContainersObserver.observe(container);
        }) 
        //////////////////////////////////////////////////
        const ProgressBarObserver = new IntersectionObserver(pgbentries => {
            pgbentries.forEach(entry => {
                this.pgbanim();
            })
        },
        {
            threshold: 0
        });
        
        pgb.forEach(pgb =>{
            ProgressBarObserver.observe(pgb);
        })         
    }

    // annimation des bars
    pgbanim = () => {
        const pgb = document.querySelectorAll('.pgb');
        pgb.forEach(element => {
            const percentage = element.dataset.percentage;

            const transform = [
                { width: '0%' },
                { width: percentage+'%' }
              ];
              const transformTiming = {
                duration: 2000,
                iterations: 1,
              }            

            element.animate(transform, transformTiming);
            element.style.width = percentage+'%';
        });
        
    }
    
    
    render(){
        return(
            <></>
        );
    }
}