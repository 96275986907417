import React from 'react';
import Home from './components/Home';
import Contact from './components/Contact';
import Loading from './components/Loading.js';
import './loadingico.css';
import './App.scss';
import Observer from './components/Observer';

export default class App extends React.Component {


      render(){
          return (
            <>
              <Home />
              <Contact />
              <Observer />
            </>
          )
      }

}
